import { NgModule, isDevMode, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import Bugsnag from "@bugsnag/js";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { UserState } from "./state-man/state/user.state";
import { JsaState } from "./state-man/state/jsa.state";
import { DailyWorkState } from "./state-man/state/daily-work.state";
import * as myGlobals from './globals';
import { WorkOrderState } from "./state-man/state/work-order.state";
import { VehicleState } from "./state-man/state/vehicle.state";
import { TechnicianState } from "./state-man/state/technician.state";
import { MrfState } from "./state-man/state/mrf.state";
import { DsrState } from "./state-man/state/dsr.state";
import { FormState } from "./state-man/state/form.state";
import { FormTypeState } from "./state-man/state/form-type.state";
import { SalesOrderItemState } from "./state-man/state/sales-order-item.state";
import { WorState } from "./state-man/state/wor.state";
import { AreaState } from "./state-man/state/area.state";
import { CustomerState } from "./state-man/state/customer.state";
import { JobTypeState } from "./state-man/state/job-type.state";
import { LocationState } from "./state-man/state/location.state";
import { UnitState } from "./state-man/state/unit.state";
import { AppConfigState } from "./state-man/state/app-config.state";
import { WorkScheduleState } from "./state-man/state/work-schedule.state";
import { PtoState } from "./state-man/state/pto.state";
import { ContactState } from "./state-man/state/contact.state";
import { AppState } from "./state-man/state/app.state";
import { AssetState } from "./state-man/state/asset.state";
import { AssetCategoryState } from "./state-man/state/asset-category.state";
import { AssetFieldState } from "./state-man/state/asset-field.state";
import { environment } from '../environments/environment';

// configure Bugsnag asap
Bugsnag.start({ 
  apiKey: 'bebd1b8e7b2c37ee9b1a14c261cfbdd3',
  appVersion: myGlobals.app_version,
  releaseStage: environment.env === 'prod' ? 'production' : environment.env?.toLowerCase()
});

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxsModule.forRoot([
      AppConfigState,
      UserState,
      DailyWorkState,
      JsaState,
      WorkOrderState,
      VehicleState,
      TechnicianState,
      MrfState,
      DsrState,
      FormState,
      FormTypeState,
      SalesOrderItemState,
      WorState,
      AreaState,
      CustomerState,
      JobTypeState,
      LocationState,
      UnitState,
      WorkScheduleState,
      PtoState,
      ContactState,
      AppState,
      AssetState,
      AssetCategoryState,
      AssetFieldState
    ], {      
      developmentMode: isDevMode()
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['user','app_config'],
      namespace: 'rt2'
    }),
    /*
    NgxsLoggerPluginModule.forRoot(),    
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !isDevMode()
    }),
    */
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    // AutoCompleteModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
