import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Asset } from '../state-man/models/asset.model';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  constructor(
    private apiService: ApiService
  ) { }
  
  public async getAll () : Promise<Asset[] | null> {      
    try {
      const ret = await this.apiService.apiReturnsAny('get', 'assets/getForAllUnits/')      
      return (ret && Array.isArray(ret)) ? Asset.buildMany(ret) : null
    } 
    catch (error) {      
      return null
    }
  }  
}
