import { v4 as uuid } from 'uuid'

export class Wor {
  uuid: string
  id: number  
  is_pending_save: boolean
  is_pending_delete: boolean
  area_id: number
  unit_id: number
  location_id: number
  customer_id: number
  contact_id: number
  job_type_id: number
  technician: string
  technician_id: number
  last_modified_by: string
  last_modified_by_id: number
  requested_by_id: number
  work_order_request_status_id: number
  status: string   // Open, id=0
  create_date: string
  est_start_date: string
  est_end_date: string    
  company_name: string  
  area: string
  job_type: string
  location_name: string
  unit_number: string
  city: string
  state: string
  job_description: string
  po_afe_number: string
  technicians: []
  assets: []
  contact_name: string
  contact_email: string
  contact_phone: string
  asset_category_id: number
  tz: string
   
  constructor() {
  }

  public async setViewModelFields()
  {
    if (!this.id) {
      this.id = 0
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
    if (!this.status) {
      this.status = ''
    }
    if (this.is_pending_save === undefined) {
      this.is_pending_save = false
    }
    if (this.is_pending_delete === undefined) {
      this.is_pending_delete = false
    }
    if (!this.assets) {
      this.assets = []
    }
    if (!this.technicians) {
      this.technicians = []
    }
  }

  static buildOne(obj: any): Wor {
    let target: Wor = Object.assign(new Wor(), obj)  
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): Wor[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Wor.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

