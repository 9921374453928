import { Injectable } from '@angular/core';
import { JsaActions } from 'src/app/state-man/actions/jsa.actions'
import { JsaService } from 'src/app/services/jsa.service'
import { LoggerService } from 'src/app/services/logger.service'
import { Jsa, JsaAndPhoto } from 'src/app/state-man/models/jsa.model'
import { Store } from "@ngxs/store";
import { JsaPhoto } from '../state-man/models/jsa-photo.model';
import { Mrf } from '../state-man/models/mrf.model';
import { MrfService } from './mrf.service';
import { MrfActions } from '../state-man/actions/mrf.actions';
import { Dsr } from '../state-man/models/dsr.model';
import { DsrService } from './dsr.service';
import { DsrActions } from '../state-man/actions/dsr.actions';
import { UuidAndId } from '../state-man/models/uuid-and-id';
import { DailyWorkActions } from '../state-man/actions/daily-work.actions';
import { WorkOrderActions } from '../state-man/actions/work-order.actions';
import { TechnicianActions } from '../state-man/actions/technician.actions';
import { Form } from '../state-man/models/form.model';
import { FormService } from './form.service';
import { FormActions } from '../state-man/actions/form.actions';
import { VehicleActions } from '../state-man/actions/vehicle.actions';
import { FormTypeActions } from '../state-man/actions/form-type.actions';
import { SalesOrderItemActions } from '../state-man/actions/sales-order-item.actions';
import { WorService } from './wor.service';
import { Wor } from '../state-man/models/wor.model';
import { WorActions } from '../state-man/actions/wor.actions';
import { AreaActions } from '../state-man/actions/area.actions';
import { CustomerActions } from '../state-man/actions/customer.actions';
import { LocationActions } from '../state-man/actions/location.actions';
import { UnitActions } from '../state-man/actions/unit.actions';
import { JobTypeActions } from '../state-man/actions/job-type.actions';
import { ServiceEntryService } from './service-entry.service';
import { WorkScheduleActions } from '../state-man/actions/work-schedule.actions';
import { PtoActions } from '../state-man/actions/pto.actions';
import { ContactActions } from '../state-man/actions/contact.actions';
import { UiService } from './ui.service';
import { UuidAndIdAndLineItems } from '../state-man/models/uuid-and-id-and-line-items';
import { UuidAndIdAndServiceEntries } from '../state-man/models/uuid-and-id-and-service-entries';
import { AssetActions } from '../state-man/actions/asset.actions';
import { AssetCategoryActions } from '../state-man/actions/asset-category.actions';
import { AssetFieldActions } from '../state-man/actions/asset-field.actions';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  is_processing_jsas = false
  is_processing_dsrs = false
  is_processing_mrfs = false
  is_processing_forms = false
  is_processing_wors = false

  constructor(
    private jsaService: JsaService,
    private mrfService: MrfService,
    private dsrService: DsrService,    
    private formService: FormService,
    private worService: WorService,
    private serviceEntryService: ServiceEntryService,
    private store: Store,
    private uiService: UiService    
    ) {}

  async processPendingJsas(jsas: Jsa[]) {    
    try {
      if (!this.is_processing_jsas) {
        this.is_processing_jsas = true
        await this.processPendingJsaDeletes(jsas)
        await this.uploadPendingJsaSaves(jsas)
        this.is_processing_jsas = false
      } 
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_jsas = false
    }
  }

  async processPendingMrfs(mrfs: Mrf[]) {
    try {
      if (!this.is_processing_mrfs) {
        this.is_processing_mrfs = true
        await this.uploadPendingMrfDeletes(mrfs)
        await this.uploadPendingMrfSaves(mrfs)
        this.is_processing_mrfs = false
      }
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_mrfs = false
    }
  }

  async processPendingWors(wors: Wor[]) {
    try {
      if (!this.is_processing_wors) {
        this.is_processing_wors = true
        await this.uploadPendingWorDeletes(wors)
        await this.uploadPendingWorSaves(wors)
        this.is_processing_wors = false
      }
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_wors = false
    }
  }

  async processPendingDsrs(dsrs: Dsr[]) {
    try {      
      if (!this.is_processing_dsrs) {        
        this.is_processing_dsrs = true
        await this.uploadPendingDsrDeletes(dsrs)
        await this.uploadPendingDsrSaves(dsrs)
        await this.updatePendingServiceEntryApprovals(dsrs)
        this.is_processing_dsrs = false
      } 
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_dsrs = false
    }
  }

  async processPendingForms(forms: Form[]) {
    try {
      if (!this.is_processing_forms) {
        this.is_processing_forms = true
        await this.uploadPendingFormDeletes(forms)
        await this.uploadPendingFormSaves(forms)
        this.is_processing_forms = false
      }
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_forms = false
    }
  }

  async uploadPendingJsaSaves(jsas: Jsa[]) {
    try {      
        for (const jsa of jsas) {    
          for (const photo of jsa.photos) {              
            if (!photo.uploaded) {              
              let apiPhoto            
              try {             
                const jsaAndPhotoCurrent: JsaAndPhoto = {jsa_id: jsa.id, photo: photo}
                this.store.dispatch(new JsaActions.ApiStartUploading(jsaAndPhotoCurrent))  
                apiPhoto = await this.jsaService.addPhoto(jsa, photo)  
                this.store.dispatch(new JsaActions.ApiStopUploading(jsaAndPhotoCurrent))          
              }
              catch (error) {                
                const jsaAndPhotoCurrent: JsaAndPhoto = {jsa_id: jsa.id, photo: photo}
                this.store.dispatch(new JsaActions.ApiStopUploading(jsaAndPhotoCurrent))  
                throw error
              }                  
              let addedPhoto:JsaPhoto = await JsaPhoto.buildOne(apiPhoto)       
              addedPhoto.uuid = photo.uuid              
              addedPhoto.file_name = photo.file_name
              addedPhoto.file_type = photo.file_type
              addedPhoto.uploaded = true           
              const jsaAndPhoto: JsaAndPhoto = {jsa_id: jsa.id, photo: addedPhoto}
              this.store.dispatch(new JsaActions.ApiAddedPhotoToJsa(jsaAndPhoto))                             
            }
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)      
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async processPendingJsaDeletes(jsas: Jsa[]) {
    try {          
        for (const jsa of jsas) {            
          for (const photo of jsa.deleted_photos) {    
            if (photo.id) {                                     
              await this.jsaService.deletePhoto(photo)
            }                       
            const jsaAndPhoto: JsaAndPhoto = {jsa_id: jsa.id, photo: photo}
            this.store.dispatch(new JsaActions.ApiDeletedPhotoFromJsa(jsaAndPhoto))                         
          }
        }      
    } catch (error) {      
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingMrfSaves(mrfs: Mrf[]) {        
    try {              
        for (const mrf of mrfs) {  
          if (mrf.is_pending_save) {
            if (mrf.id <= 0) {
              const apiObj = await this.mrfService.addMrf(mrf)                
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = mrf.uuid
                const mrf_line_items = apiObj.mrf_line_items
                const payload: UuidAndIdAndLineItems = {uuid, id, mrf_line_items}                             
                this.store.dispatch(new MrfActions.ApiAddedOne(payload))  
              } 
            } else {
              const apiObj = await this.mrfService.updateMrf(mrf)                
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = mrf.uuid
                const mrf_line_items = apiObj.mrf_line_items
                const payload: UuidAndIdAndLineItems = {uuid, id, mrf_line_items}                                   
                this.store.dispatch(new MrfActions.ApiUpdatedOne(payload))  
              } 
            }
          }
        }  
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingMrfDeletes(mrfs: Mrf[]) {    
    try {              
        for (const mrf of mrfs) {  
          if (mrf.is_pending_delete) {                    
            const status = await this.mrfService.deleteMrf(mrf)                 
            if (status && status === 'OK') {
              const id: number = mrf.id
              const uuid: string = mrf.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new MrfActions.ApiDeletedOne(payload))  
            }             
          }
        }     
    } catch (error) {         
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingWorSaves(wors: Wor[]) {    
    try {              
        for (const wor of wors) {  
          if (wor.is_pending_save) {
            if (wor.id <= 0) {
              const apiObj = await this.worService.addWor(wor)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = wor.uuid
                const payload: UuidAndId = {uuid, id}                   
                this.store.dispatch(new WorActions.ApiAddedOne(payload))  
              } 
            } else {
              const apiObj = await this.worService.updateWor(wor)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = wor.uuid
                const payload: UuidAndId = {uuid, id}                   
                this.store.dispatch(new WorActions.ApiUpdatedOne(payload))  
              } 
            }
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingWorDeletes(wors: Wor[]) {    
    try {              
        for (const wor of wors) {  
          if (wor.is_pending_delete) {            
            const status = await this.worService.deleteWor(wor)              
            if (status && status === 'OK') {
              const id: number = wor.id
              const uuid: string = wor.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new WorActions.ApiDeletedOne(payload))  
            }             
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingDsrSaves(dsrs: Dsr[]) : Promise<void> {    
    try {      
        for (const dsr of dsrs) {  
          if (dsr.is_pending_save) {
            if (dsr.id <= 0) {
              const apiObj = await this.dsrService.addDsr(dsr)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = dsr.uuid
                const service_entries = apiObj.service_entries
                const payload: UuidAndIdAndServiceEntries = {uuid, id, service_entries}   
                this.store.dispatch(new DsrActions.ApiAddedOne(payload))  
              } 
            } else {
              const apiObj = await this.dsrService.updateDsr(dsr)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = dsr.uuid
                const service_entries = apiObj.service_entries
                const payload: UuidAndIdAndServiceEntries = {uuid, id, service_entries}                   
                this.store.dispatch(new DsrActions.ApiUpdatedOne(payload))  
              } 
            }
          }
        }  
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingDsrDeletes(dsrs: Dsr[]) {    
    try {              
        for (const dsr of dsrs) {  
          if (dsr.is_pending_delete) {            
            const status = await this.dsrService.deleteDsr(dsr)              
            if (status && status === 'OK') {
              const id: number = dsr.id
              const uuid: string = dsr.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new DsrActions.ApiDeletedOne(payload))  
            }             
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async updatePendingServiceEntryApprovals(dsrs: Dsr[]) {    
    try {      
        for (const dsr of dsrs) {            
          const ids = []
          let employee_approval_date = null
          for (const se of dsr.service_entries) {
            if (se.is_approval_pending) {                     
              ids.push(se.id)  
              employee_approval_date = se.employee_approval_date            
            } 
          }   
          
          if (ids.length && employee_approval_date) {                       
            await this.serviceEntryService.approve(ids, employee_approval_date)              
            this.store.dispatch(new DsrActions.ApiUpdatedServiceEntriesApproval(dsr))   
          }
          
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingFormSaves(iforms: Form[]) {    
    try {   
      
      
      const forms: Form[] = []
      for (const of of iforms) {
          if (of.id <= 0) {
              // Only keep pending forms (id === 0) if the guid does not already 
              // exist with a non-zero id.
              const found = iforms.find(f => f.id !== 0 && f.uuid === of.uuid)
              if (!found) {
                  // Only keep form if don't already have one with the same id/uuid
                  const found2 = forms.find(f => f.id === of.id && f.uuid === of.uuid)
                  if (!found2) forms.push(of) 
              }
          } else {
              // Only keep form if don't already have one with the same id/uuid
              const found2 = forms.find(f => f.id === of.id && f.uuid === of.uuid)
              if (!found2) forms.push(of) 
          }
      }

      for (const form of forms) {          
        if (form.is_pending_save) {
          if (form.id <= 0) {
            const apiObj = await this.formService.addForm(form)  
            if (apiObj) {
              const id: number = apiObj.id
              const uuid: string = form.uuid
              const payload: UuidAndId = {uuid, id}                 
              this.store.dispatch(new FormActions.ApiAddedOne(payload))  
            } 
          } else {
            const apiObj = await this.formService.updateForm(form)  
            if (apiObj) {
              const id: number = apiObj.id
              const uuid: string = form.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new FormActions.ApiUpdatedOne(payload))  
            } 
          }
        }
      }                    
    } catch (error) {          
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingFormDeletes(forms: Form[]) {    
    try {              
        for (const form of forms) {  
          if (form.is_pending_delete) {            
            const status = await this.formService.deleteForm(form)              
            if (status && status === 'OK') {
              const id: number = form.id
              const uuid: string = form.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new FormActions.ApiDeletedOne(payload))  
            }             
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async refresh() {
    try {      
      //this.uiService.startWait("Refreshing...");
      this.store.dispatch(new PtoActions.Refresh())
      this.store.dispatch(new JsaActions.Refresh())
      this.store.dispatch(new MrfActions.Refresh()) 
      this.store.dispatch(new DsrActions.Refresh())
      this.store.dispatch(new DailyWorkActions.Refresh())
      this.store.dispatch(new WorkOrderActions.Refresh())
      this.store.dispatch(new TechnicianActions.Refresh())
      this.store.dispatch(new FormActions.Refresh())
      this.store.dispatch(new VehicleActions.Refresh())
      this.store.dispatch(new FormTypeActions.Refresh())
      this.store.dispatch(new SalesOrderItemActions.Refresh())
      this.store.dispatch(new WorActions.Refresh())
      this.store.dispatch(new AreaActions.Refresh())
      this.store.dispatch(new CustomerActions.Refresh())
      this.store.dispatch(new ContactActions.Refresh())
      this.store.dispatch(new LocationActions.Refresh())
      this.store.dispatch(new UnitActions.Refresh())
      this.store.dispatch(new JobTypeActions.Refresh())
      this.store.dispatch(new WorkScheduleActions.Refresh())
      this.store.dispatch(new AssetActions.Refresh())
      this.store.dispatch(new AssetCategoryActions.Refresh())
      this.store.dispatch(new AssetFieldActions.Refresh())

      // Do this to trigger uploading pending data
      await this.trigger_pending()
      //this.uiService.stopWait()
    } catch (e) {
      //this.uiService.stopWait()
      LoggerService.log(e)
    }  
  }  

  async trigger_pending() {
    try {
      // Do this to trigger uploading pending data
      this.store.dispatch(new JsaActions.IncrementPendingCount())
      this.store.dispatch(new MrfActions.IncrementPendingCount())
      this.store.dispatch(new DsrActions.IncrementPendingCount())
      this.store.dispatch(new FormActions.IncrementPendingCount())
      this.store.dispatch(new WorActions.IncrementPendingCount())
    } catch (e) {
      LoggerService.log(e)
    }  
  }  

}
