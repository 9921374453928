import { Unit } from "../models/unit.model";
import { UnitStateModel } from "../state/unit.state";

export namespace UnitActions {
    export class Refresh {
        static readonly type = '[API-Unit] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Unit] Init'
        constructor(public payload: UnitStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Unit] Set'
        constructor(public payload: Unit[]) {}
    }
}
