import { Location } from "../models/location.model";
import { LocationStateModel } from "../state/location.state";

export namespace LocationActions {
    export class Refresh {
        static readonly type = '[API-Location] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-Location] Init'
        constructor(public payload: LocationStateModel) {}
    }
    export class Set {
        static readonly type = '[API-Location] Set'
        constructor(public payload: Location[]) {}
    }
}
