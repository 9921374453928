import { MrfLineItem } from "./mrf-line-item.model"
import { v4 as uuid } from 'uuid'
import { Location } from "./location.model"

export class Mrf {
  uuid: string
  id: number  
  is_pending_save: boolean
  is_pending_delete: boolean
  mrf_status_id: number
  engine_make_model: string
  compressor_make_model: string
  mrf_notes: string
  work_order_id: number
  work_order_number: string
  mrf_line_items: MrfLineItem[]
  status: string
  created_at: string
  company_name: string
  requestor: string
  last_modified_by: string
  location_name: string
  unit_number: string
  job_type: string
  tz: string
  
  constructor() {
  }

  public async setViewModelFields()
  {
    if (!this.id) {
      this.id = 0
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
    if (!this.status) {
      this.status = ''
    }
    if (this.is_pending_save === undefined) {
      this.is_pending_save = false
    }
    if (this.is_pending_delete === undefined) {
      this.is_pending_delete = false
    }
  }

  static buildOne(obj: any): Mrf {
    let target: Mrf = Object.assign(new Mrf(), obj)  
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): Mrf[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Mrf.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

