
import { v4 as uuid } from 'uuid'
import { FormSalesOrderItem } from './form-sales-order-item.model'

export class Form {
  uuid: string
  id: number  
  is_pending_save: boolean
  is_pending_delete: boolean
  form_status_id: number
  form_data: string
  created: string
  date_worked: string
  form_type_id: number
  work_order_id: number
  author_id: number  
  submitted_at: string  
  approved_by_id: number  
  rejected_by_id: number
  rejected_reason: string
  is_edited: boolean
  form_name: string
  deleted_by: string
  area: string
  work_order_number: string
  status: string
  company_name: string
  contact: string
  location_name: string
  unit_number: string
  job_type: string
  job_description: string
  author: string
  approved_by: string
  rejected_by: string

  sales_order_items: FormSalesOrderItem[]
  tech_sig: string

  other_items: []

  group_uuid: string
  group_number: number
  form_identifier: string
  tz: string
  
  constructor() { 
  }

  public async setViewModelFields()
  {
    if (!this.id) {
      this.id = 0
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
    if (!this.status) {
      this.status = ''
    }
    if (this.is_pending_save === undefined) {
      this.is_pending_save = false
    }
    if (this.is_pending_delete === undefined) {
      this.is_pending_delete = false
    }
    if (!this.sales_order_items) {
      this.sales_order_items = []
    }
    if (!this.group_uuid) {
      this.group_uuid = uuid()
    }
    if (!this.group_number) {
      this.group_number = 1
    }
  }

  static buildOne(obj: any): Form {
    let target: Form = Object.assign(new Form(), obj)  
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): Form[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Form.buildOne(objs[i]))
      }
    }
    return lobjs
  }

  static formatDeviceNumber(group_number: number, work_order_number: string) {
    if (!group_number) group_number = 1
    return work_order_number + '-' + group_number
  }
}

