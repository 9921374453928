export class Location {
  id: number
  customer_id: number
  name: string
  engine_driver_make: string
  engine_driver_model: string
  engine_driver_sn: string  
  compressor_make: string
  compressor_model: string
  compressor_sn: string  
  control_panel_make: string
  control_panel_model: string
  control_panel_sn: string
  tz: string
  
  constructor() {
  }

  static buildOne(obj: any): Location {
    let target: Location = Object.assign(new Location(), obj)
    return target
  }

  static buildMany(objs: any[]): Location[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Location.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

