import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Unit } from '../state-man/models/unit.model';
import { UiService } from './ui.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  constructor(
    private apiService: ApiService,
    private uiService: UiService
  ) { }

  public async getAll () : Promise<Unit[] | null> {      
    try {
      const ret = await await this.apiService.apiReturnsAny('get', 'unit/active/')         
      return (ret && Array.isArray(ret)) ? Unit.buildMany(ret) : null
    } 
    catch (error) {     
      LoggerService.log(error + 'unit/active/')   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')     
      return null
    }
  }  
  
}
