
import { v4 as uuid } from 'uuid'
import { ServiceEntry } from './service-entry.model'

export class Dsr {
  uuid: string
  id: number  
  is_pending_save: boolean
  is_pending_delete: boolean
  dsr_type: string
  work_order_id: number
  work_order_number: string
  company_name: string
  area: string
  technician: string
  technician_id: number
  last_modified_by: string
  last_modified_by_id: number
  status: string
  dsr_status_id: number
  rejected_reason: string
  unit_id: number
  location_name: string
  unit_number: string
  job_type: string
  service_entries: ServiceEntry[] 
  is_late: boolean
  engine_driver_hours: number
  compressor_hours: number
  control_panel_hours: number  
  created: string
  submitted: string
  date_worked: string
  summary_of_work_performed: string
  is_job_complete: boolean
  cust_sig: string
  tech_sig: string
  is_customer_signature_unavailable: boolean
  is_full_day: boolean
  type: number
  hasEdits: boolean
  assets: []
  tz: string
  
  constructor() { 
  }

  public async setViewModelFields()
  {
    if (!this.id) {
      this.id = 0
    }
    if (!this.uuid) {
      this.uuid = uuid();
    }
    if (!this.status) {
      this.status = ''
    }
    if (!this.service_entries) {
      this.service_entries = []
    } else {
      this.service_entries = ServiceEntry.buildMany(this.service_entries)
    }
    if (this.is_pending_save === undefined) {
      this.is_pending_save = false
    }
    if (this.is_pending_delete === undefined) {
      this.is_pending_delete = false
    }
    if (!this.assets) {
      this.assets = []
    }
  }

  static buildOne(obj: any): Dsr {
    let target: Dsr = Object.assign(new Dsr(), obj)  
    target.setViewModelFields()
    return target
  }

  static buildMany(objs: any[]): Dsr[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Dsr.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

