export class Unit {
  id: number
  location_id: number
  name: string
  engine_driver_make: string
  engine_driver_model: string
  engine_driver_sn: string  
  compressor_make: string
  compressor_model: string
  compressor_sn: string  
  control_panel_make: string
  control_panel_model: string
  control_panel_sn: string
  city: string
  state: string
  county: string
  tz: string
  
  constructor() {
  }

  static buildOne(obj: any): Unit {
    let target: Unit = Object.assign(new Unit(), obj)
    return target
  }

  static buildMany(objs: any[]): Unit[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(Unit.buildOne(objs[i]))
      }
    }
    return lobjs
  }
}

